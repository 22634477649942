import request from '@/plugins/request'

//订单管理
export function orderList (data = {}) {
  return request({
    url: `/api/order/parent/getOrderList`,
    method: 'POST',
    data: data,
  })
}

// 订单详情
export function getOrderDetail (orderId = '') {
  return request({
    url: `/api/order/detailed/getOrderDetailed`,
    method: 'GET',
    params: { orderId },
  })
}

// 手动发货
export function numberSend (data = {}) {
  return request({
    url: `/api/order/logistic/numberSend`,
    method: 'POST',
    data: data,
  })
}

// 售后订单详情

export function getOrderAfterSales (orderId = '') {
  return request({
    url: `/api/order/afterSales/getOrderAfterSales`,
    method: 'GET',
    params: { orderId },
  })
}


//评价管理


export default {
  //订单管理
  orderList,
  getOrderDetail,
  numberSend,

  //评价管理

}