<template>
  <el-dialog 
    title="订单状态"
    :visible="visible || value"
    :close-on-click-modal="true"
    :before-close="handleClose"
  >
    <el-form v-loading="loading">
      <el-form-item prop="status" label="当前订单状态：">
        <div> 交易成功 </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'statusDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: ''
    },
  },
  data(){
    return{
      loading: false,
    }
  },
  watch: {
    itemId: {
      handler: function(newVal){
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    }
  },
  methods: {
    handleClose(){
      this.$emit('update:visible', false)
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="less" scoped>

</style>