<template>
  <div>
    <gl-title title="订单列表"></gl-title>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="orderId" label="订单搜索">
          <gl-list-search
            v-model="mFormData.orderId"
            placeholder="订单ID"
          ></gl-list-search>
        </el-form-item>
        <el-form-item prop="orderStatus" label="订单状态">
          <gl-option
            v-model="mFormData.orderStatus"
            :list="statusList"
          ></gl-option>
        </el-form-item>
        <el-form-item prop="orderType" label="订单类型">
          <gl-option
            v-model="mFormData.orderType"
            :list="orderTypeList"
          ></gl-option>
        </el-form-item>
        <el-form-item prop="buyerName" label="下单人昵称">
          <gl-list-search
            v-model="mFormData.buyerName"
            placeholder="下单人昵称"
          ></gl-list-search>
        </el-form-item>
        <el-form-item prop="receiveName" label="收货人名称">
          <gl-list-search
            v-model="mFormData.receiveName"
            placeholder="收货人名称"
          ></gl-list-search>
        </el-form-item>
        <el-form-item prop="receivePhone" label="收货人电话">
          <gl-list-search
            v-model="mFormData.receivePhone"
            placeholder="收货人电话"
          ></gl-list-search>
        </el-form-item>
        <el-form-item prop="createTimeStart" label="下单时间">
          <gl-date-picker
            :startTime.sync="mFormData.createTimeStart"
            :endTime.sync="mFormData.createTimeEnd"
          ></gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="mReload">
            搜索
          </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column
          type="index"
          :index="mIndexMethod"
          label="序号"
          align="center"
          min-width="30"
        ></el-table-column>
        <el-table-column
          prop="orderId"
          label="订单ID"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="itemName"
          label="商品标题"
          width="130px"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="skuName" label="商品规格"></el-table-column>
        <el-table-column prop="buyerName" label="下单人昵称"></el-table-column>
        <el-table-column
          prop="receiveName"
          label="收货人名称"
        ></el-table-column>
        <el-table-column
          prop="receivePhone"
          label="收货人电话"
        ></el-table-column>
        <el-table-column
          prop="receiveAddress"
          label="收货人地址"
        ></el-table-column>
        <el-table-column prop="orderStatus" label="订单状态">
          <template slot-scope="{ row }">
            <span>{{ orderStatusGather[row.orderStatus] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="订单时间"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="payPrice" label="订单金额"></el-table-column>
        <el-table-column prop="payType" label="付款方式">
          <template slot-scope="scope">
            <span>{{ payTypeGather[scope.row.payType] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderType" label="订单类型">
          <template slot-scope="scope">
            <span>{{
              scope.row.orderType == 1 ? '普通订单' : '批发订单'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" width="130px" fixed="right">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="mClickAction('mUpdateVisible',scope.row.orderId)"> 订单状态 </el-button> -->
            <!-- <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.orderId)"> 查看 </el-button> -->
            <el-button
              type="text"
              @click="
                $router.push({
                  path: 'detail',
                  query: { orderId: scope.row.orderId },
                })
              "
            >
              查看
            </el-button>

            <el-button
              type="text"
              v-if="scope.row.afterSalesId"
              style="color: red"
              @click="
                $router.push({
                  path: 'refund',
                  query: { orderId: scope.row.orderId },
                })
              "
            >
              售后
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      >
      </gl-pagination>
    </gl-card>
    <statusDialog :itemId="mItemId" :visible.sync="mUpdateVisible" />
    <detailDialog :itemId="mItemId" :visible.sync="mPreviewVisible" />
  </div>
</template>

<script>
import { orderList } from '@/api/order'
import ListMixin from '@/mixins/list.mixin.js'
import statusDialog from './status-dialog.vue'
import detailDialog from './detail-dialog.vue'
export default {
  components: { statusDialog, detailDialog },
  mixins: [ListMixin],
  data() {
    return {
      statusList: [
        { name: '全部', value: '' },
        { name: '待付款', value: '1' },
        { name: '待发货', value: '2' },
        { name: '待收货', value: '3' },
        { name: '待评价', value: '4' },
        { name: '已完成', value: '5' },
        { name: '已取消', value: '6' },
        { name: '已关闭', value: '7' },
      ],
      orderTypeList: [
        { name: '全部', value: '' },
        { name: '普通订单', value: '1' },
        { name: '批发订单', value: '2' },
      ],
      //table
      payTypeGather: {
        1: '微信',
        2: '支付宝',
        3: '余额',
        4: '线下支付',
      },
    }
  },
  computed: {
    orderStatusGather() {
      let gather = {}
      this.statusList.forEach((item, index) => {
        let key = item.value
        if (key) gather[key] = item.name
      })
      return gather
    },
  },
  mounted() {
    this.mGetListFun = orderList
    this.mGetList()
  },
}
</script>

<style></style>
